/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import classNames from "classnames";
import Button from "Accessories/Button/Button";
import "./Navigation.scss";

const TopNavigation = (props) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      menu: allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Main Menu"}}) {
        edges {
          node {
            name
            items {
              title
              object_slug
              wordpress_id
              items: wordpress_children {
                title
                object_slug
                wordpress_id
              }
            }
          }
        }
      }
    }
  `)

  const [menuOverlay, setMenuOverlay] = useState(false);
  const [logoOverlay, setLogoOverlay] = useState(false);
  const { location } = props
  const [yCursor, setYCursor] = useState(0);
  const [marginOne, setMarginOne] = useState(0);
  const [marginTwo, setmarginTwo] = useState(0);
  const [marginThree, setmarginThree] = useState(0);
  const [marginFour, setmarginFour] = useState(0);

  function onMouseMove(e) {
    setYCursor((100 - (e.clientY / window.innerHeight * 100)).toFixed());
  }

  function onClick(e) {
    const x = e.clientX;

    if(x < (window.innerWidth / 2)) {
      setMenuOverlay(false)
    }
  }

  const styles = {
    height: `${yCursor}%`
  };

  const getRandomInt = (minValue, maxValue) => {
    const min = Math.ceil(minValue);
    const max = Math.floor(maxValue);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  function handleScroll() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const windowScrolldistance = window.pageYOffset;

    if (windowScrolldistance >= windowHeight) {
      setLogoOverlay(true)
    } else {
      setLogoOverlay(false)
    }
  }

  useEffect(
    () => {
      setMarginOne(getRandomInt(10, 100));
      setmarginTwo(getRandomInt(10, 100));
      setmarginThree(getRandomInt(10, 100));
      setmarginFour(getRandomInt(10, 100));

      window.addEventListener('scroll', handleScroll);

      const className = 'modal-open';
      const element = window.document.body;
      if (menuOverlay) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
      return function cleanup() {
        element.classList.remove(className);
        window.removeEventListener('scroll', handleScroll)
      };
    },
    [menuOverlay] // Only re-call effect when value changes
  );

  const handleCloseClick = () => {
    setMenuOverlay(false)
  }

  const handleMenuClick = () => {
    setMenuOverlay(true)
  }

  const renderMenuOverlay = () => {

    const { menu } = data
    const mainMenu = menu.edges[0]

    return (
      <>
        <div
          id="menu-overlay"
          className={classNames(menuOverlay && "is-open", "menu-overlay")}
          onMouseMove={(e) => onMouseMove(e)}
        >
          <div id="menu-column-wrapper" className="menu-column-wrapper column-wrapper d-flex flex-column flex-md-row justify-content-start">
            <Button
              className="btn-menu-close mb-4"
              onClick={handleCloseClick}
              text="Close"
            />
            <nav className="d-flex flex-column primary-menu">
              <ul>
                {
                  mainMenu.node.items.map(menuItem => (
                    <li key={menuItem.object_slug} className="text-lg nav-level-1-item mb-2">
                      <Link
                        to={`/${menuItem.object_slug}/`}
                        className="menu-primary text-link"
                        activeClassName="is-active"
                      >
                        {menuItem.title}
                      </Link>
                      {menuItem.items &&
                        (
                          <ul>
                            {menuItem.items.map((subMenuItem) => (
                              <li key={subMenuItem.wordpress_id} className="text-md nav-level-2-item">
                                <Link
                                  to={(menuItem.object_slug === "research-practice") ? `/${menuItem.object_slug}/${subMenuItem.object_slug}/` : `/${subMenuItem.object_slug}/`}
                                  className="menu-secondary text-link"
                                  activeClassName="is-active"
                                >
                                  {subMenuItem.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )
                      }
                    </li>
                  ))
                }
              </ul>
            </nav>
          </div>
          <div className="bg-shape" style={styles} />
        </div>
        <div className={classNames(menuOverlay && "is-open", "menu-closer")} onClick={(e) => onClick(e)} onKeyDown={(e) => onClick(e)} role="button" />
      </>
    );
  }

  return (
    <div className="header">
      <div className="navigation d-flex justify-content-between">
        <Link
          to="/"
          className={(location.pathname === "/" || location.pathname === "/c-p-t--bme3H4/" ? classNames(logoOverlay && "is-visible", "logo is-hidden") : "logo")}
          activeClassName="is-active"
        >
          <span>(</span>
          <span className="single-letter d-inline-block" style={{ marginRight : `${marginOne}px`, marginLeft : `${marginTwo}px`}}>C</span>
          <span className="single-letter d-inline-block" style={{ marginRight : `${marginThree}px`}}>P</span>
          <span className="single-letter d-inline-block" style={{ marginRight : `${marginFour}px`}}>T</span>
          <span>)</span>
        </Link>
        <div className="menu-btn-container d-flex">
          <Button
            className="btn-black"
            onClick={handleMenuClick}
            text="Menu"
          />
        </div>
      </div>
      {renderMenuOverlay()}
    </div>
  );
}

export default TopNavigation
