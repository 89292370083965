const baseUrl = process.env.BASE_URL
  ? process.env.BASE_URL
  : "dev.owd.io/c-p-t-cms--fdafee";
const protocol = process.env.BASE_PROTOCOL
  ? process.env.BASE_PROTOCOL
  : "https";
const pathPrefix = process.env.BASE_PATH_PREFIX
  ? process.env.BASE_PATH_PREFIX
  : "/";

const assetPrefix = `${protocol}://${baseUrl}${pathPrefix}`;

module.exports = {
  siteTitle: "CPT", // Site title.
  siteTitleAlt: "The Center for Philosophical Technologies", // Alternative site title for SEO.
  siteBrand: "CPT", // Site brand.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteSeoDefaultImage: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://cpt-live.netlify.com/", // Domain of your website without pathPrefix.
  baseUrl,
  protocol,
  assetPrefix,
  pathPrefix, // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "The Center for Philosophical Technologies (CPT) is a strategic initiative of Arizona State University. The CPT is a global hub for critical and speculative research on philosophy, technology, and design. The center supports work through project design and development, publications, faculty and student fellowships, and global education initiatives.", // Website description used for RSS feeds/meta description tag.
  postDefaultCategoryID: "Design" // Default category for posts.
};
